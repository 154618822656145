<template>
  <div class="create-member">
    <CForm
      ref="createMember"
      v-on:submit.prevent="createFolder"
      novalidate
      class="create-member-form"
    >
      <!--  HEADER    -->
      <CreateHeader
        :loading="false"
        :descriptions="descriptions"
        :title="texts.createFolderPage.title"
      />
      <!--   CONTENT   -->
      <div class="form-content content">
        <!--   Parent Folder Path     -->
        <ClFormRow
          :label="texts.createFolderPage.parentLabel"
          :toolTipText="descriptions.parentFolderPath"
        >
          <template #content>
            <CInput
              :value="formData.parentFolderPath"
              v-model="formData.parentFolderPath"
              add-input-classes="col-sm-12"
              disabled="true"
            />
          </template>
        </ClFormRow>
        <!--    Repository ID    -->
        <ClFormRow
          :label="texts.createFolderPage.repoIdLabel"
          :toolTipText="descriptions.repositoryID"
        >
          <template #content>
            <CInput
              :value="formData.repositoryId"
              v-model="formData.repositoryId"
              add-input-classes="col-sm-12"
              disabled="true"
            />
          </template>
        </ClFormRow>
        <!--     NAME   -->
        <ClFormRow
          :label="texts.createFolderPage.nameLabel"
          :toolTipText="descriptions.name"
        >
          <template #content>
            <CInput
              :placeholder="texts.createFolderPage.namePlaceholder"
              v-model="formData.fileName"
              add-input-classes="col-sm-12"
              required
              invalidFeedback="Required"
              :is-valid="nameValidate"
            />
          </template>
        </ClFormRow>
        <!--   TAGS    -->
        <ClFormRow
          :label="texts.createFolderPage.tagsLabel"
          :toolTipText="descriptions.tags"
        >
          <template #content>
            <multiselect
              v-model="formData.tags"
              :options="options"
              :multiple="true"
              :preserve-search="true"
              :taggable="true"
              :hide-selected="true"
              @tag="addTags"
              :selectLabel="selectLabel"
              :tagPlaceholder="tagPlaceholder"
              :deselectLabel="removeLabel"
              class="custom-multi-select zq--multiselect zq--multiselect__green"
            >
              <template #caret>
                <div></div>
              </template>
            </multiselect>
            <img
              src="../../../assets/icons/search.svg"
              width="16"
              height="16"
              alt="search"
              class="zq--multiselect-search-icon"
            >
          </template>
        </ClFormRow>
        <!--    CUSTOM FIELDS    -->
        <ClFormRow :label="texts.createFolderPage.customFieldsLabel" :toolTipText="descriptions.customFields">
          <template #content>
            <multiselect
              v-model="formData.customFields"
              :options="customFieldsOptions"
              :multiple="true"
              :preserve-search="true"
              :taggable="true"
              :hide-selected="true"
              @tag="addCustomFields"
              :selectLabel="selectLabel"
              :tagPlaceholder="tagPlaceholder"
              label="name"
              track-by="id"
              :placeholder="texts.createFolderPage.customFieldsPlaceholder"
              class="custom-multi-select zq--multiselect__search zq--multiselect"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                {{ option.name }}
              </template>
            </multiselect>
            <img
              src="../../../assets/icons/search.svg"
              width="16"
              height="16"
              alt="search"
              class="zq--multiselect-search-icon"
            >
          </template>
        </ClFormRow>
        <!--     METADATA     -->
        <ClFormRow
          :label="texts.createFolderPage.metadataLabel"
          tooltipPosition="label"
          xl-label="12"
          md-content="12"
          xl-content="12"
          :toolTipText="descriptions.metadata"
        >
          <template #content>
            <CMetaInputs
              v-on:change="addMeta"
              :fields="formData.metadata"
            />
          </template>
        </ClFormRow>
      </div>
    </CForm>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import routerBreadcrumbs from "@/router/breadcrumb/routerBreadcrumbs";
import CMetaInputs from '@/components/forms/CMetaInputs';
import ClFormRow from "@/shared/components/formComponents/ClFormRow";
import CreateHeader from "@/shared/components/CreateHeader";
import {formConfig} from "@/config";
import {webAssets} from "@/config/descriptions/webAssets"
import {webAssetsTexts} from "@/config/pageTexts/webAssets.json";

export default {
  name: 'CreateFolder',
  components: {
    CMetaInputs,
    ClFormRow,
    CreateHeader,
  },

  data() {
    return {
      descriptions: {
        ...webAssets.folder.create
      },
      texts: {
        ...webAssetsTexts
      },
      customFieldsOptions: [],
      selectLabel: formConfig.selectLabel,
      tagPlaceholder: formConfig.tagPlaceholder,
      removeLabel: formConfig.removeLabel,
      formData: {
        name: '',
        mimeType: '',
        metadata: [],
        parentFolderPath: '',
        tags: [],
        customFields: [],
        repositoryId: '',
      },
      nameValidate: '',
      name: String,
      options: ['tag1', 'tag2', 'tag3'],
    };
  },
  created() {
    this.initialize();
  },
  computed: {
    ...mapGetters('files', ['files', 'success', 'message', 'loading']),
  },
  watch: {
    formData: {
      deep: true,
      handler: function (val) {
        if (val.name) this.nameValidate = true;
        if (val.id) this.idValidate = true;
      },
    },
  },
  methods: {
    ...mapActions('files', ['handleCreateFolder', 'handleGetFileById']),
    ...mapActions('customFields', ['handleGetCustomFields']),
    initialize() {
      this.formData.parentFolderPath = this.$route.params.name;
      this.formData.repositoryId = this.$route.params.id;
      routerBreadcrumbs(this.$router.currentRoute);
      this.getCustomFields();
    },
    createFolder() {
      this.nameValidate = !!this.formData.fileName;

      let customFields = [];
      if (this.formData.customFields.length) {
        this.formData.customFields.forEach(field => {
          customFields.push(field.id);
        })
      }

      const body = {
        repositoryId: this.formData.repositoryId,
        name: this.formData.fileName,
        tags: this.formData.tags,
        metadata: this.formData.metadata,
        parentFolderPath: this.formData.parentFolderPath,
        mimeType: 'application/x-directory',
        customFields: customFields
      };

      if (this.nameValidate) {
        this.handleCreateFolder(body);
      }
    },
    getCustomFields() {
      this.handleGetCustomFields({
        queryData: {},
        sortBy: this.sortBy,
        skip: 0,
        limit: this.itemsPerPage,
      }).then((items) => {
        items.forEach(item => {
          this.customFieldsOptions.push({id: item.id, name: item.name});
        })
      });
    },
    addTags(newTag) {
      this.options.push(newTag);
      this.formData.tags.push(newTag);
    },
    addMeta(meta) {
      this.formData.metadata = meta;
    },
    addCustomFields(val) {
      this.customFieldsOptions.push(val);
      this.formData.customFields = [...this.formData.customFields, val]
    },
  },
}
</script>

<style lang="scss">
.create-member {
  height: 100%;
  .create-member-form {
    height: calc(100% - 50px);
    .form-content {
      background: var(--zq-sub-bg);
      height: 100%;
      border-top: 1px solid var(--zq-content-border-color);
    }
  }
}
</style>
